import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import sanityQuery from "src/lib/sanityQuery";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { useBloc } from "src/state/state";
import type {
  CmsImageRootObject,
  SimpleCmsImageData
} from "src/types/sanitySchema";

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-auto-rows: 55px;
`;

const Logo = styled.div`
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

interface Props {
  images: SimpleCmsImageData[];
}

const ImageList: FC<Props> = ({ images }) => {
  const [{ data }, { fetchAll }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<CmsImageRootObject>()
  });

  useEffect(() => {
    void fetchAll(
      sanityQuery.getByIds(
        images.map((image) => JSON.stringify(image.asset._ref)).join(",")
      )
    );
  }, []);

  return (
    <List>
      {data.map((image) => (
        <Logo key={image._id}>
          <img
            style={{ borderRadius: "unset" }}
            src={image.url}
            alt={image.originalFilename}
          />
        </Logo>
      ))}
    </List>
  );
};

export default ImageList;
